
import { Component, Vue } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  name: "CustomerAgreement",
  components: {
    // HelloWorld,
    pdf,
  },
})
export default class CustomerAgreement extends Vue {
  private currentPage: number = 1; // pdf文件页码
  private pageCount: number = 41; // pdf文件总页数
  private src: string = "/docx/CLIENT_AGREEMENTMU.pdf"; // pdf文件地址
  private loadPdfHandler() {
    this.currentPage = 1; // 加载的时候先加载第一页
  }
  mounted() {
    this.src = pdf.createLoadingTask(this.src);
  }
}
